import useAuth from "@/composables/auth";
import useUserProfile from "@/composables/user/userProfile";
import { FRONT_CHAT_ID } from "@/config";

// Refs to user and user profile
const {
  fullName,
  fetchUserFrontEmailHash,
  userFrontHash,
  userProfile,
  fetchUserProfile,
} = useUserProfile();
const { user } = useAuth();

const showChatWidget = () => {
  // Check if zE is a function before calling it
  if (isChatWidgetReady()) {
    window.FrontChat("show");
  } else {
    console.warn("Front chat widget is not available.");
    window.open("https://moverlyhc.frontkb.com/en", "_blank");
  }
};

const waitAndHideChatWidget = () => {
  const checkFrontChat = setInterval(() => {
    if (isChatWidgetReady()) {
      clearInterval(checkFrontChat);
      window.FrontChat("shutdown");
    }
  }, 150); // Poll every 150ms
};

const waitAndShowChatWidget = () => {
  const checkFrontChat = setInterval(() => {
    if (isChatWidgetReady()) {
      clearInterval(checkFrontChat);
      window.FrontChat("init", {
        chatId: FRONT_CHAT_ID,
        useDefaultLauncher: true,
        onInitCompleted: window.onFrontChatInitCompleted || function () {},
      });
    }
  }, 150); // Poll every 150ms
};

const isChatWidgetReady = () => typeof window.FrontChat === "function";

const createFrontInitHandler = () => {
  // Define the onInitCompleted handler which is stubbed in Google Tag Manager
  window.onFrontChatInitCompleted = function () {
    console.log("Front chat widget initialized.");
    // Set up the widget so that, once it's opened, we authenticate the user see https://dev.frontapp.com/docs/identify-users
    // We don't use this unbind function but it could be passed up to the main App to tear down the handler
    window.FrontChat("onWindowVisibilityChanged", async function (params) {
      if (params.is_window_visible) {
        // Every time the chat is opened, re-send the user info in case it changed, e.g. they logged in
        if (user.value) {
          await frontIdentifyUser();
        } else {
          console.log(
            "User is not logged in, so not authenticating with Front."
          );
        }
      }
    });
  };
};

const frontIdentifyUser = async () => {
  await fetchUserFrontEmailHash(); // Get hash from our backend
  await fetchUserProfile();
  if (isChatWidgetReady()) {
    window.FrontChat("identity", {
      name: fullName.value,
      userHash: userFrontHash.value.frontHash,
      email: user.value.email,
      customFields: {
        accountRole: userProfile.value?.accountRole,
      },
    });
  }
};

export default function useFront() {
  return {
    createFrontInitHandler,
    isChatWidgetReady,
    showChatWidget,
    waitAndHideChatWidget,
    waitAndShowChatWidget,
  };
}
