<template>
  <icon class="icon icon--warning">
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="icon__canvas"
      width="24"
      height="24"
    >
      <path
        d="M12 19.925q-.625 0-1.062-.437-.438-.438-.438-1.063t.438-1.062q.437-.438 1.062-.438t1.062.438q.438.437.438 1.062t-.438 1.063q-.437.437-1.062.437Zm-1.375-4.9V3.575h2.75v11.45Z"
        class="icon__glyph icon__glyph--warning"
      />
    </svg>
  </icon>
</template>

<script>
import Icon from "./IconBase";

export default {
  name: "WarningIcon",
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
.icon__glyph--warning {
  fill: $color-warning;
}
</style>
