const pluralise = (count, one, many) => {
  if (count === 1) return one;

  return many;
};

const toTitleCase = (dirtyString) => {
  return dirtyString
    .split(" ")
    .map((word) => {
      const firstChar = word.charAt(0);

      // Handle "29A"
      if (isNaN(firstChar)) {
        return `${firstChar.toUpperCase()}${word.slice(1).toLowerCase()}`;
      }
      return word;
    })
    .join(" ");
};

const format = (template, ...args) => {
  return template.replace(/{([0-9]+)}/g, function (match, index) {
    return typeof args[index] === "undefined" ? match : args[index];
  });
};

const pluraliseWord = (word) => {
  const irregulars = {
    person: "people",
  };

  if (irregulars[word.toLowerCase()]) {
    return irregulars[word.toLowerCase()];
  }

  // Rules for regular plurals
  if (word.match(/[sxz]$/) || word.match(/[^aeiou]h$/)) {
    return word + "es"; // box→boxes, brush→brushes
  } else if (word.match(/[^aeiou]y$/)) {
    return word.slice(0, -1) + "ies"; // city→cities
  } else {
    return word + "s"; // seller→sellers
  }
};

const toSentenceCase = (dirtyString) => {
  if (!dirtyString) return dirtyString;
  return (
    dirtyString.charAt(0).toUpperCase() + dirtyString.slice(1).toLowerCase()
  );
};

const capitalisePostcode = (address) => {
  if (!address) return address;
  return address.replace(
    /([A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s*\d[A-Za-z]{2})/gi,
    (match) => match.toUpperCase()
  );
};

export default function useText() {
  return {
    capitalisePostcode,
    format,
    pluralise,
    pluraliseWord,
    toSentenceCase,
    toTitleCase,
  };
}
