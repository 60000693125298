<script setup>
import Icon from "./IconBase";
</script>

<script>
export default {
  name: "ReportIcon",
};
</script>

<template>
  <Icon class="icon icon--report">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      class="icon__canvas"
    >
      <path
        class="icon__glyph icon__glyph--report"
        d="m105-399-65-47 200-320 120 140 160-260 120 180 135-214 65 47-198
      314-119-179-152 247-121-141-145 233Zm475 159q42 0
      71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM784-80
      676-188q-21 14-45.5 21t-50.5 7q-75 0-127.5-52.5T400-340q0-75
      52.5-127.5T580-520q75 0 127.5 52.5T760-340q0 26-7 50.5T732-244l108 108-56
      56Z"
      />
    </svg>
  </Icon>
</template>
