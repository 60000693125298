/**
 * This might be specific to TA documents, but it's not clear yet.
 */
import Honeybadger from "@honeybadger-io/js";
import useChecks from "@/composables/flags/checks";
import useParticipants from "@/composables/participants";
import useUserProfile from "@/composables/user/userProfile";
import useVerifiedClaims from "@/composables/verifiedClaims";
import { FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS } from "@/config";
import { computed, ref } from "vue";
import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { usePDTF } from "@/composables/pdtf";

const { createVerifiedClaim, addVerifiedClaim, toClaimDateTime } =
  useVerifiedClaims();
const { filterFlagsByName, checks, findChecksByCategory } = useChecks();
const { fullName, userId } = useUserProfile();
const { getStateByPath } = usePDTF();
const { getParticipantsByRole, participantRoles } = useParticipants();

const sellerSignatures = ref([]);

const SELLER_SIGNATURE_STATE = Object.freeze({
  NOT_READY_TO_SIGN: "warning",
  READY_TO_SIGN: "info",
  SIGNED: "success",
});

const FORM_NAME = Object.freeze({
  TA6: "ta6",
  TA7: "ta7",
  TA10: "ta10",
});

const mapFlagCheckNameToFormName = Object.freeze({
  ta6Completion: FORM_NAME.TA6,
  ta7Completion: FORM_NAME.TA7,
  ta10Completion: FORM_NAME.TA10,
});

const mapFlagCheckNameToDocumentName = Object.freeze({
  ta6Completion: "Property information",
  ta7Completion: "Leasehold information",
  ta10Completion: "Fixtures, fittings, and contents",
});

const sortDocumentsByTaNumber = (documents) => {
  return documents.sort((a, b) => {
    const aName = a.formName.replace(/\D/g, "");
    const bName = b.formName.replace(/\D/g, "");

    if (Number(aName) > Number(bName)) return 1;
    if (Number(aName) < Number(bName)) return -1;

    return 0;
  });
};

const documentsToSign = computed(() => {
  const documentFlags = filterFlagsByName(
    ["ta6Completion", "ta7Completion", "ta10Completion"],
    checks.value
  );

  return sortDocumentsByTaNumber(
    documentFlags.map((flag) => {
      return {
        formName: mapFlagCheckNameToFormName[flag.checkName],
        documentName: mapFlagCheckNameToDocumentName[flag.checkName],
      };
    })
  );
});

const fetchViewableImages = async (transactionId, options) => {
  const fetchViewableImages = httpsCallable(functions, "populateTAForm", {
    limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
  });
  const defaultOptions = {
    asImages: true,
    width: 800,
    formName: FORM_NAME.TA6,
  };
  try {
    const response = await fetchViewableImages({
      ...defaultOptions,
      ...options,
      transactionId,
    });
    return response.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Unable to generate form images",
      name: "documentSigning.js",
      params: {
        transactionId,
        options,
      },
    });
    throw ex;
  }
};

const createSignatureClaim = () => {
  return createVerifiedClaim({
    path: "/propertyPack/saleReadyDeclarations/sellerSignatures/-",
    voucherName: fullName.value,
    data: {
      name: fullName.value,
      signedOn: toClaimDateTime(new Date()),
      externalIds: {
        Moverly: userId.value,
      },
    },
  });
};

const signDocument = async (transactionId) => {
  const signatureClaim = createSignatureClaim();

  try {
    await addVerifiedClaim(transactionId, signatureClaim);
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Seller signature claim was not created",
      name: "documentSigning.js",
      params: {
        transactionId,
        signatureClaim,
      },
    });
    throw ex;
  }
};

const hasCurrentUserSigned = computed(() => {
  return sellerSignatureFlag.value?.severity === SELLER_SIGNATURE_STATE.SIGNED;
});

const hasAllUsersSigned = computed(() =>
  sellerSignatureFlags.value.every(
    ({ severity }) => severity === SELLER_SIGNATURE_STATE.SIGNED
  )
);

const sellerSignatureFlag = computed(() => {
  return filterFlagsByName(
    `sellerSignature-${userId.value}`,
    checks.value,
    true
  );
});

const sellerSignatureFlags = computed(() => {
  return findChecksByCategory("seller-signatures", checks.value);
});

const isReadyToSignOrHasSigned = computed(() => {
  return sellerSignatureFlags.value.every((flag) =>
    [
      SELLER_SIGNATURE_STATE.READY_TO_SIGN,
      SELLER_SIGNATURE_STATE.SIGNED,
    ].includes(flag?.severity)
  );
});

/**
 * Used to display information from the state such as the name.
 */
const mapParticipantAsMetadata = (participants, sellerSignatureFlags) => {
  return sellerSignatureFlags.map((sellerSignature) => {
    const [, checkUserId] = sellerSignature.checkName.split("-");

    const participant = participants.find(
      ({ externalIds }) => externalIds?.Moverly == checkUserId
    );
    return {
      ...sellerSignature,
      metadata: participant,
    };
  });
};

const additionalSignatories = computed(() => {
  const participants = getParticipantsByRole(participantRoles.SELLER);
  const additionalSignatoryFlags = sellerSignatureFlags.value.filter(
    ({ checkName }) => {
      const [, checkUserId] = checkName.split("-");

      return checkUserId !== userId.value;
    }
  );

  return mapParticipantAsMetadata(participants, additionalSignatoryFlags);
});

export default function useDocumentSigning() {
  // Done here to prevent reactive changes on the page the user is on.
  sellerSignatures.value = getStateByPath(
    "/propertyPack/saleReadyDeclarations/sellerSignatures",
    []
  );

  return {
    SELLER_SIGNATURE_STATE,
    additionalSignatories,
    createSignatureClaim,
    documentsToSign,
    hasAllUsersSigned,
    fetchViewableImages,
    hasCurrentUserSigned,
    isReadyToSignOrHasSigned,
    sellerSignatureFlag,
    sellerSignatureFlags,
    signDocument,
  };
}
