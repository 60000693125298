import { ref } from "vue";
import axios from "axios";
import { MOVEREADY_ORGANISATION_API_URL, APP_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import Honeybadger from "@honeybadger-io/js";

const isFetchingBranding = ref(false);
const isCoBrandingEnabled = ref(false);
const brandName = ref("");
const brandLogo = ref("");
const brandLogoScale = ref("");
const organisationLogo = ref("");

const setDefaultBranding = (branding) => {
  isCoBrandingEnabled.value = false;
  brandLogoScale.value = "";
  brandName.value = "Moverly";
  brandLogo.value = `${APP_URL}/assets/images/logos/moverly-wordmark.svg`;
  organisationLogo.value = branding?.organisationLogo || "";
};

const setCoBranding = (branding) => {
  isCoBrandingEnabled.value = true;
  brandLogoScale.value = branding?.brandLogoScale;
  brandName.value = branding?.brandName || "Moverly";
  const brandLogoFilename = branding?.brandLogo;

  if (brandLogoFilename) {
    brandLogo.value = `${APP_URL}/assets/images/logos/${brandLogoFilename}`;
  } else {
    brandLogo.value = `${APP_URL}/assets/images/logos/moverly-wordmark.svg`;
  }

  organisationLogo.value = branding?.organisationLogo || "";
};

const fetchBranding = async (transactionId) => {
  const appCheckToken = await getAppCheckToken();
  isFetchingBranding.value = true;

  try {
    const brandingResponse = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/branding/${transactionId}`,
      {
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    const branding = brandingResponse.data;

    if (branding?.isCoBrandingEnabled) {
      setCoBranding(branding);
    } else {
      setDefaultBranding(branding);
    }
  } catch (ex) {
    setDefaultBranding();
    Honeybadger.notify(ex, {
      name: "branding.js",
      message: `Failed to fetch branding for transaction ${transactionId}`,
    });
  } finally {
    isFetchingBranding.value = false;
  }
};

setDefaultBranding();

export default function useBranding() {
  return {
    brandLogo,
    brandLogoScale,
    brandName,
    fetchBranding,
    isCoBrandingEnabled,
    isFetchingBranding,
    organisationLogo,
    setDefaultBranding,
  };
}
