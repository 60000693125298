<script setup>
import Icon from "./IconBase";
</script>

<script>
export default {
  name: "RunningIcon",
};
</script>
<template>
  <icon class="icon icon--running">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon__canvas"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        class="icon__glyph icon__glyph--running"
        d="M480-240q100 0 170-70t70-170q0-100-70-170t-170-70v240L310-310q35 33 78.5 51.5T480-240Zm0 160q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
      />
    </svg>
  </icon>
</template>
