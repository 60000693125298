/**
 * Currently, requiresPurchase items are not removed after they've been
 * purchased. This will need to be resolved in the backend, or handled here.
 *
 * TODO Possibly remove the individual hasPurchasedProduct computed properties
 * as they are getting quite large, though the reactivity it still useful
 * in some areas.
 */
import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import productType from "@/enums/product-type";
import useAuth from "@/composables/auth";
import { MOVEREADY_PDTF_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";

const { getAccessToken } = useAuth();

const purchasedProducts = ref([]);
const requiresPurchase = ref([]);

const fetchPurchasedProducts = async (transactionId) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/products`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    purchasedProducts.value = response.data.purchasedProducts;
    requiresPurchase.value = Array.isArray(response.data?.requiresPurchase)
      ? response.data.requiresPurchase
      : [];
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to fetch transaction products",
      name: "transactionProducts.js",
      params: {
        transactionId,
      },
    });
    purchasedProducts.value = [];
    throw ex;
  }
};

const isUpdatingTransactionProducts = ref(false);

const updateRequiresPurchase = async (
  transactionId,
  productPurchaseRequirement
) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isUpdatingTransactionProducts.value = true;

  try {
    await axios.patch(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/requires-purchase`,
      {
        requiresPurchase: productPurchaseRequirement,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    // Perhaps have this returned by the endpoint?
    requiresPurchase.value = [
      ...requiresPurchase.value,
      productPurchaseRequirement,
    ];

    return purchasedProducts.value;
  } catch (ex) {
    // A conflicting state, already patched.
    if (ex.response?.status === 409) return purchasedProducts.value;

    Honeybadger.notify(ex, {
      message: "Failed to update required transaction products",
      name: "transactionProducts.js",
      params: {
        transactionId,
        productPurchaseRequirement,
      },
    });

    throw ex;
  } finally {
    isUpdatingTransactionProducts.value = false;
  }
};

const updateTransactionProducts = async (transactionId, productToPurchase) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isUpdatingTransactionProducts.value = true;

  try {
    await axios.patch(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/products`,
      {
        purchasedProduct: productToPurchase,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    // Perhaps have this returned by the endpoint?
    purchasedProducts.value = [...purchasedProducts.value, productToPurchase];
    return purchasedProducts.value;
  } catch (ex) {
    // A conflicting state - the Property Pack has been purchased.
    if (ex.response?.status === 409) return;

    Honeybadger.notify(ex, {
      message: "Failed to update transaction products",
      name: "transactionProducts.js",
      params: {
        transactionId,
        productToPurchase,
      },
    });

    throw ex;
  } finally {
    isUpdatingTransactionProducts.value = false;
  }
};

const orderLandlordIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.LANDLORD_IDENTITY_CHECK
  );
};

const orderTenantIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.TENANT_IDENTITY_CHECK
  );
};

const orderBuyerIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.BUYER_IDENTITY_CHECK
  );
};

const orderSellerIdentityChecks = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.SELLER_IDENTITY_CHECK
  );
};

const confirmMaterialInformationEstateAgentPurchase = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.MATERIAL_INFORMATION_PACK
  );
};

const createContractReadyPurchaseOrderForVendor = async (transactionId) => {
  await updateRequiresPurchase(
    transactionId,
    productType.CONTRACT_READY_PACK_SELLER
  );
};

/**
 * Not a purchase order, but it's a similar concept.
 * It's a "free" product that the estate agent can order.
 */
const createConsumerMaterialInformationPackPurchaseOrder = async (
  transactionId
) => {
  await updateRequiresPurchase(transactionId, "materialInformationPack");
};

const createConnellsSaleReadyPurchaseOrder = async (transactionId) => {
  await updateRequiresPurchase(transactionId, productType.CONNELLS_SALE_READY);
};

const confirmMaterialInformationSellerPurchase = async (transactionId) => {
  await updateTransactionProducts(
    transactionId,
    productType.CONTRACT_READY_PACK_SELLER
  );
};

const hasPurchasedProduct = (productName) => {
  return purchasedProducts.value.some((product) =>
    [productName].includes(product)
  );
};

const hasPurchasedLandlordIdentityCheck = computed(() => {
  return hasPurchasedProduct(productType.LANDLORD_IDENTITY_CHECK);
});

const hasPurchasedTenantIdentityCheck = computed(() => {
  return hasPurchasedProduct(productType.TENANT_IDENTITY_CHECK);
});

const hasPurchasedSellerIdentityCheck = computed(() => {
  return hasPurchasedProduct(productType.SELLER_IDENTITY_CHECK);
});

const hasPurchasedBuyerIdentityCheck = computed(() => {
  return hasPurchasedProduct(productType.BUYER_IDENTITY_CHECK);
});

const hasPurchasedConnellsSaleReady = computed(() => {
  return hasPurchasedProduct(productType.CONNELLS_SALE_READY);
});

const hasPurchasedConnellsHomeConveyancing = computed(() => {
  return hasPurchasedProduct(productType.CONNELLS_HOME_CONVEYANCING);
});

const hasPurchasedConnellsBuyerHomeConveyancing = computed(() => {
  return hasPurchasedProduct(productType.CONNELLS_BUYER_HOME_CONVEYANCING);
});

const isConnellsSaleReadyRequired = computed(() => {
  return requiresPurchase.value.some((product) =>
    [productType.CONNELLS_SALE_READY].includes(product)
  );
});

const hasPurchasedMaterialInformationPack = computed(() => {
  return purchasedProducts.value.some((product) =>
    [
      productType.MATERIAL_INFORMATION_PACK,
      productType.MATERIAL_INFORMATION_PACK_SELLER,
    ].includes(product)
  );
});

const hasPurchasedContractReadyPack = computed(() => {
  return purchasedProducts.value.some((product) =>
    [
      productType.CONTRACT_READY_PACK,
      productType.CONTRACT_READY_PACK_SELLER,
    ].includes(product)
  );
});

const hasPurchasedInsights = computed(() => {
  return hasPurchasedProduct(productType.INSIGHTS);
});

/**
 * Only the seller MI product is required to be purchased.
 * But for completeness, we'll check for both.
 */
const isSellerMaterialInformationPurchaseRequested = computed(() => {
  return requiresPurchase.value.some((product) =>
    [
      productType.MATERIAL_INFORMATION_PACK,
      productType.MATERIAL_INFORMATION_PACK_SELLER,
    ].includes(product)
  );
});

const isSellerSaleReadyRequired = computed(() => {
  return requiresPurchase.value.some((product) =>
    [
      productType.CONTRACT_READY_PACK,
      productType.CONTRACT_READY_PACK_SELLER,
    ].includes(product)
  );
});

const hasPurchasedIdentityChecks = computed(() => {
  return (
    hasPurchasedBuyerIdentityCheck.value ||
    hasPurchasedLandlordIdentityCheck.value ||
    hasPurchasedSellerIdentityCheck.value ||
    hasPurchasedTenantIdentityCheck.value
  );
});

/**
 * There's a mix of purchase and requires purchase products.
 * So this handles the mixed usage. This also just prevents
 * a load of 409 errors being thrown if the agent returns
 * to the service options page to select new options.
 */
const saveServiceOptions = async (transactionId, serviceOptions = []) => {
  const serviceOptionsToSave = [];

  if (
    !hasPurchasedMaterialInformationPack.value &&
    serviceOptions.includes(productType.MATERIAL_INFORMATION_PACK)
  ) {
    serviceOptionsToSave.push(
      confirmMaterialInformationEstateAgentPurchase(transactionId)
    );
  }

  if (
    !hasPurchasedMaterialInformationPack.value &&
    serviceOptions.includes(
      productType.MATERIAL_INFORMATION_PACK_CONSUMER_CHARGE
    )
  ) {
    serviceOptionsToSave.push(
      createConsumerMaterialInformationPackPurchaseOrder(transactionId)
    );
  }

  if (
    !hasPurchasedContractReadyPack.value &&
    serviceOptions.includes(productType.CONTRACT_READY_PACK_SELLER)
  ) {
    serviceOptionsToSave.push(
      createContractReadyPurchaseOrderForVendor(transactionId)
    );
  }

  if (
    !isConnellsSaleReadyRequired.value &&
    serviceOptions.includes(productType.CONNELLS_SALE_READY)
  ) {
    serviceOptionsToSave.push(
      createConnellsSaleReadyPurchaseOrder(transactionId)
    );
  }

  return Promise.all(serviceOptionsToSave);
};

const isUserIdentityCheckRequired = (userId) => {
  const isUserIdentityCheckRequired = purchasedProducts.value.find(
    (productName) => productName === `identityCheck-${userId}`
  );

  return !!isUserIdentityCheckRequired;
};

const removePurchasedProduct = (purchasedProductToDelete) => {
  purchasedProducts.value = purchasedProducts.value.filter(
    (purchasedProduct) => purchasedProduct !== purchasedProductToDelete
  );
};

const deletePurchasedProduct = async (
  transactionId,
  purchasedProductToDelete
) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.delete(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/products`,
      {
        data: { purchasedProduct: purchasedProductToDelete },
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    removePurchasedProduct(purchasedProductToDelete);
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to delete transaction purchased product",
      name: "transactionProducts.js",
      params: {
        transactionId,
        purchasedProductToDelete,
      },
    });
    throw ex;
  }
};

const deleteRequiresPurchaseProduct = async (
  transactionId,
  requiresPurchaseProductToDelete
) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.delete(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/requires-purchase/consumer-charges`,
      {
        data: { requiresPurchase: requiresPurchaseProductToDelete },
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to delete transaction purchased product",
      name: "transactionProducts.js",
      params: {
        transactionId,
        requiresPurchaseProductToDelete,
      },
    });
    throw ex;
  }
};

export default function useTransactionProducts() {
  return {
    confirmMaterialInformationEstateAgentPurchase,
    confirmMaterialInformationSellerPurchase,
    createConnellsSaleReadyPurchaseOrder,
    createContractReadyPurchaseOrderForVendor,
    deletePurchasedProduct,
    createConsumerMaterialInformationPackPurchaseOrder,
    fetchPurchasedProducts,
    hasPurchasedBuyerIdentityCheck,
    hasPurchasedConnellsBuyerHomeConveyancing,
    hasPurchasedConnellsHomeConveyancing,
    hasPurchasedConnellsSaleReady,
    hasPurchasedContractReadyPack,
    deleteRequiresPurchaseProduct,
    hasPurchasedIdentityChecks,
    hasPurchasedLandlordIdentityCheck,
    hasPurchasedMaterialInformationPack,
    hasPurchasedSellerIdentityCheck,
    hasPurchasedTenantIdentityCheck,
    hasPurchasedInsights,
    isConnellsSaleReadyRequired,
    isSellerMaterialInformationPurchaseRequested,
    isSellerSaleReadyRequired,
    isUserIdentityCheckRequired,
    orderBuyerIdentityChecks,
    orderLandlordIdentityChecks,
    orderSellerIdentityChecks,
    orderTenantIdentityChecks,
    purchasedProducts,
    requiresPurchase,
    saveServiceOptions,
    updateRequiresPurchase,
    updateTransactionProducts,
  };
}
