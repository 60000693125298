/**
 * Left in FEATURE_UPFRONT_SEARCHES for now as an example to
 * use for future feature flags.
 * Leave in commented code to show how to use organisationSettings for feature flags.
 */
import { FEATURE_UPFRONT_SEARCHES } from "@/config";
import useOrganisationSettings from "@/composables/organisation/organisationSettings";
import { computed } from "vue";
const { organisationSettings } = useOrganisationSettings();

export const UPFRONT_SEARCHES = Symbol("Upfront searches and payment.");
export const CONTRACTS = Symbol("Contracts and agency agreements.");
export const NUDGES = Symbol("Nudges");

const organisationFeatures = computed(() => {
  return Array.isArray(organisationSettings.value?.features)
    ? organisationSettings.value?.features
    : [];
});

// Make features a computed property instead of a static object
const features = computed(() => ({
  [UPFRONT_SEARCHES]: FEATURE_UPFRONT_SEARCHES,
  [CONTRACTS]: true, // organisationFeatures.includes("contracts"), // Set to be visible to all
  [NUDGES]: organisationFeatures.value.includes("nudges"),
}));

export function isFeatureEnabled(featureToCheck) {
  return features.value[featureToCheck];
}
