/**
 * Back-end errors don't always return a specific message, so this provides an
 * alternative, user-friendly message.
 */
export default Object.freeze({
  "invite/410":
    "Your invite has expired. Please get in touch with your agent to get a new one.",
  "create-invite/409":
    "The email may already be in use. Please try an alternative.",
  "create-invite/422":
    "The information provided is not correct. Please check and try again.",
  "invite/404":
    "An invite could not be found. Please get in touch with your agent to request a new one.",
  "invite/401":
    "You've signed in with an email that isn't associated with your invite. Please sign out and try again using the email address that received the invite.",
  "invite/409": "The invite has already been accepted.",
  "invite/500":
    "There's been a problem accepting the invite. Please try again or contact us for assistance.",
  "add-executive-to-transaction/409":
    "You are already associated with this transaction.",
  "office/404": "The office could not be found.",
  "offices/404": "The offices for your organisation could not be found.",
  "office-settings/404":
    "The office settings for your office could not be found.",
  "office-settings/500":
    "There's been a problem updating the office settings. Please try again or contact us for assistance.",
  "office-user/422":
    "The offices could not be assigned. The offices supplied were not in an expected format. Please try again.",
  "organisation-invite/delete/404": "The invite could not be found.",
  "organisation-invite/delete/500":
    "There's been a problem deleting the invite. Please try again or contact us for assistance.",
  "organisation-invite/delete/409": "The invite has already been deleted.",
  "organisation-invite/create/409":
    "The invite for this email has already been created.",
  "identity-verification/applicant/400":
    "The identity verification is either in progress or has been completed. Please wait for it to complete or contact Moverly for assistance.",
  "contract/field-map/500":
    "There's been a problem fetching the contract field map. Please try again or contact us for assistance.",
  "contract/json-schema/500":
    "There's been a problem fetching the contract json schema. Please try again or contact us for assistance.",
  "contract/populate-pdf/500":
    "There's been a problem populating the PDF contract. Please try again or contact us for assistance.",
  "title-search-properties-by-description/500":
    "There's been a problem fetching the titles.",
  "title-search-properties-by-map/500":
    "There's been a problem fetching the titles.",
  "title-search-properties-by-map/404":
    "No results for this property from HM Land Registry.",
  "title-search-properties-by-title-number/404":
    "No results for this title number from HM Land Registry.",
  "title-search-properties-by-title-number/500":
    "There's been a problem fetching the addresses.",
  "address-search-by-title-number/no-addresses-found":
    "No addresses found for this title number. This might be due to an error with HM Land Registry. Please search again to see if the address is available.",
});
