/**
 * contractId is synonymous with fileId - which is the original template of the contract.
 */
import Honeybadger from "@honeybadger-io/js";
import md5 from "md5";
import useAuth from "@/composables/auth";
import useChecks from "@/composables/flags/checks";
import useErrors from "@/composables/errors";
import useVerifiedClaims from "@/composables/verifiedClaims";
import { FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS } from "@/config";
import { checkSeverity } from "@/enums/checks";
import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { ref, watchEffect, computed } from "vue";
import { usePDTF } from "@/composables/pdtf";

const { findChecksByCategory, checks, fetchFlagByName } = useChecks();
const { toDisplayMessage } = useErrors();
const { getStateByPath } = usePDTF();
const { userId } = useAuth();
const { createVerifiedClaim, addVerifiedClaim, toClaimDateTime } =
  useVerifiedClaims();

const contracts = ref([]);
const contractFlags = ref([]);
const userContractFlags = ref([]);

const CONTRACT_SIGNATURE_STATE = Object.freeze({
  NOT_READY_TO_SIGN: checkSeverity.WARNING,
  SIGNED_ON_BEHALF: checkSeverity.INFO, // e.g. At lease one agent has signed
  SIGNED: checkSeverity.SUCCESS,
});

watchEffect(() => {
  const allContractFlags = findChecksByCategory(
    "contract-signatures",
    checks.value
  );
  userContractFlags.value = allContractFlags.filter(({ checkName }) =>
    checkName.endsWith(`-${userId.value}`)
  );
  contractFlags.value = [...allContractFlags];
});

const fetchContracts = () => {
  contracts.value = getStateByPath("/contracts", []);

  return contracts.value;
};

const findContractByFileId = (
  fileIdToFind,
  contractsToSearch = contracts.value
) => {
  return contractsToSearch.find(
    (contractToSearch) =>
      contractToSearch?.contract?.template?.externalIds?.Moverly ===
      fileIdToFind
  );
};

const getContractIndexByFileId = (fileIdToFind) => {
  const contractsToSearch = fetchContracts();

  return contractsToSearch.findIndex(
    (contractToSearch) =>
      contractToSearch?.contract?.template?.externalIds?.Moverly ===
      fileIdToFind
  );
};

const retrieveEmptyContract = async (fileId) => {
  try {
    const getFieldMap = httpsCallable(functions, "retrieveEmptyContract", {
      limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
    });
    const fieldMapResponse = await getFieldMap({
      fileId: "rcbgz4i0JujZL2nWb1id",
    });

    return fieldMapResponse?.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "contracts.js",
      message: `Failed to populate pdf contract.`,
      params: {
        fileId,
      },
    });
    const errorMessage = toDisplayMessage(
      {
        response: { status: 500, message: ex.message },
      },
      "contracts"
    );
    throw new Error(errorMessage);
  }
};

const hasCurrentUserSignedAllContracts = computed(() => {
  return userContractFlags.value.every(
    ({ severity }) => severity === CONTRACT_SIGNATURE_STATE.SIGNED
  );
});

const hasAllUsersSignedAllContracts = computed(() => {
  console.log("contractFlags", contractFlags.value);
  return contractFlags.value.every(
    ({ severity }) => severity === CONTRACT_SIGNATURE_STATE.SIGNED
  );
});

const hasAnyUserSignedContract = (contractIdToFind) => {
  const matchingContracts = contractFlags.value.filter(
    (contractFlag) => contractFlag?.metadata?.contractId === contractIdToFind
  );

  if (matchingContracts.length === 0) {
    return false;
  }

  return matchingContracts.some(
    (contract) => contract?.severity === CONTRACT_SIGNATURE_STATE.SIGNED
  );
};

const createContractHash = (contractState) => {
  return md5(JSON.stringify(contractState?.contract || {}));
};

const getSignatureIndexByUserId = (contractIdToFind, userIdToFind) => {
  const contractsToSearch = fetchContracts();

  const contractMatch = contractsToSearch.find((contract) => {
    return contract.contract.template.externalIds.Moverly === contractIdToFind;
  });

  const signaturesToSearch = contractMatch?.signatures || [];

  const signatureIndex = signaturesToSearch.findIndex((signature) => {
    return signature?.externalIds?.Moverly === userIdToFind;
  });

  return signatureIndex === -1 ? "-" : signatureIndex;
};

const getSignatureClaimPath = (contractId, userId) => {
  const contractIndex = getContractIndexByFileId(contractId);
  const signatureIndex = getSignatureIndexByUserId(contractId, userId);

  return `/contracts/${contractIndex}/signatures/${signatureIndex}`;
};

const createSignatureClaim = (contractId, userId, signatoryName, role) => {
  const contractState = findContractByFileId(contractId);
  const contractHash = createContractHash(contractState);
  const path = getSignatureClaimPath(contractId, userId);
  const data = {
    name: signatoryName,
    signedOn: toClaimDateTime(new Date()),
    role,
    externalIds: {
      Moverly: userId,
    },
    contractHash,
  };

  return createVerifiedClaim({
    path,
    voucherName: signatoryName,
    data,
  });
};

const signContract = async (
  transactionId,
  contractId,
  userId,
  signatoryName,
  role
) => {
  const signatureClaim = createSignatureClaim(
    contractId,
    userId,
    signatoryName,
    role
  );

  try {
    await addVerifiedClaim(transactionId, signatureClaim);
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Contract signature claim was not created",
      name: "contracts.js",
      params: {
        transactionId,
        signatureClaim,
        contractId,
        userId,
      },
    });
    throw ex;
  }
};

const signContracts = async (transactionId, userId, signatoryName, role) => {
  return Promise.all(
    userContractFlags.value.map((contractFlag) => {
      const { contractId } = contractFlag?.metadata || {};
      return signContract(
        transactionId,
        contractId,
        userId,
        signatoryName,
        role
      );
    })
  );
};

const contractsToSign = computed(() => {
  return userContractFlags.value;
});

const additionalSignatories = computed(() => {
  const additionalSignatoryFlags = contractFlags.value.filter(
    ({ metadata }) => {
      return metadata.userId !== userId.value;
    }
  );

  return additionalSignatoryFlags;
});

const fetchViewableImages = async (transactionId, fileId, options = {}) => {
  try {
    const populatePdfContract = httpsCallable(
      functions,
      "populatePdfContract",
      {
        limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
      }
    );

    const defaultOptions = {
      asImages: true,
      width: 800,
    };

    const requestOptions = {
      ...defaultOptions,
      ...options,
    };

    const fieldMapResponse = await populatePdfContract({
      transactionId,
      fileId,
      ...requestOptions,
    });

    // Qucik fix from the back-end bug of not supplying the page total
    if (requestOptions.asImages) {
      const totalPages = fieldMapResponse?.data?.results.length;

      return { ...fieldMapResponse?.data, totalPages };
    }

    return fieldMapResponse.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "contractSetup.js",
      message: `Failed to populate pdf contract.`,
      params: {
        fileId,
      },
    });
    const errorMessage = toDisplayMessage(
      {
        response: { status: 500, message: ex.message },
      },
      "contract/populate-pdf"
    );
    throw new Error(errorMessage);
  }
};

const isContractSigned = (contractId, userId) => {
  const contractToCheck = fetchFlagByName(
    `contractSignature-${contractId}-${userId}`
  );

  return contractToCheck?.severity === CONTRACT_SIGNATURE_STATE.SIGNED;
};

export default function useContracts() {
  return {
    CONTRACT_SIGNATURE_STATE,
    additionalSignatories,
    contractFlags,
    contracts,
    contractsToSign,
    createContractHash,
    fetchContracts,
    fetchViewableImages,
    findContractByFileId,
    getContractIndexByFileId,
    getSignatureClaimPath,
    getSignatureIndexByUserId,
    hasAllUsersSignedAllContracts,
    hasAnyUserSignedContract,
    hasCurrentUserSignedAllContracts,
    isContractSigned,
    retrieveEmptyContract,
    signContract,
    signContracts,
    userContractFlags,
  };
}
