export default Object.freeze({
  HELP_HOME: "https://moverlyhc.frontkb.com/en",
  //Customer charges
  CUSTOMER_CHARGES_GENERAL: "https://moverlyhc.frontkb.com/en/articles/3144194",
  CUSTOMER_CHARGES_SELLER: "https://moverlyhc.frontkb.com/en/articles/3144194",
  CUSTOMER_CHARGES_LANDLORD:
    "https://moverlyhc.frontkb.com/en/articles/3144194",
  CUSTOMER_CHARGES_BUYER: "https://moverlyhc.frontkb.com/en/articles/3144194",
  CUSTOMER_CHARGES_TENANT: "https://moverlyhc.frontkb.com/en/articles/3144194",

  // Landing pages
  AGENTS: "https://moverlyhc.frontkb.com/en/categories/711810-agents",
  SELLERS: "https://moverlyhc.frontkb.com/en/categories/712258-sellers",
  CONVEYANCERS:
    "https://moverlyhc.frontkb.com/en/categories/712514-conveyancers",
  BUYERS: "https://moverlyhc.frontkb.com/en/categories/712834-buyers",
  LANDLORDS:
    "https://moverlyhc.frontkb.com/en/categories/712642-landlords-and-lettings",
  TENANTS:
    "https://moverlyhc.frontkb.com/en/categories/713026-renters-and-tenants",

  ORGANISATION_AGENCY_AGREEMENTS:
    "https://moverlyhc.frontkb.com/en/articles/3419010",

  TITLE_SEARCH: "https://moverlyhc.frontkb.com/en/articles/3301762",

  NUDGE_SETTINGS: "https://moverlyhc.frontkb.com/en/articles/3942082",
});
