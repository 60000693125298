import Honeybadger from "@honeybadger-io/js";
import accountRoleName from "@/enums/account-role-name";
import axios from "axios";
import participantRoles from "@/enums/participant-roles";
import useAuth from "@/composables/auth";
import useBackOfficeBranding from "@/composables/brandingBackOffice";
import useOrganisationSettings from "@/composables/organisation/organisationSettings";
import { MOVEREADY_PDTF_API_URL, MOVEREADY_USER_API_URL } from "@/config";
import { computed, ref } from "vue";
import { getAppCheckToken } from "@/firebase";

const { userId, getAccessToken } = useAuth();
const { setOrganisationSettings } = useOrganisationSettings();
const { setBranding } = useBackOfficeBranding();

export const userProfile = ref({});
const isLoadingUserProfile = ref(true);
const userTransactions = ref([]);
const userProfileTransactions = ref({});
const userTransactionsError = ref(null);
const userProfileError = ref(null);
const organisationId = ref(null);
const userOrganisation = ref(null);
const errorMessage = ref("");
const isOrganisationOwner = computed(() => userProfile.value?.isOwner);
const userOffices = ref([]);
export const userFrontHash = ref({});

let fetchUserProfileRequest = null;

const fetchUserProfile = async (force) => {
  if (force === true) {
    fetchUserProfileRequest = null;
  }

  if (fetchUserProfileRequest) {
    return fetchUserProfileRequest;
  }

  isLoadingUserProfile.value = true;
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = await getAccessToken();

  fetchUserProfileRequest = axios
    .get(`${MOVEREADY_USER_API_URL}/users/${userId.value}`, {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
    .then((response) => {
      userProfile.value = response.data;
      organisationId.value = response.data?.organisationId || null;
      userProfileTransactions.value = response.data?.transactions || [];
      hydrateUserTransactions(response.data?.transactions || []);
      userOffices.value = response.data?.officesIds || [];
      userProfileError.value = null;
      setOrganisationProfile(response.data);
    })
    .catch((ex) => {
      userProfileError.value = ex;
      userProfile.value = {};
      Honeybadger.notify(ex, {
        name: "userProfile.js",
        message: "Failed to load user profile.",
        params: {
          userId: userId.value,
        },
      });
    })
    .finally(() => {
      isLoadingUserProfile.value = false;
      fetchUserProfileRequest = null;
    });

  return fetchUserProfileRequest;
};

let fetchUserFrontHashRequest = null;

const fetchUserFrontEmailHash = async () => {
  if (fetchUserFrontHashRequest) {
    return fetchUserFrontHashRequest;
  }

  const appCheckToken = await getAppCheckToken();
  const userAccessToken = await getAccessToken();

  fetchUserFrontHashRequest = axios
    .get(`${MOVEREADY_USER_API_URL}/users/me/frontemailhash`, {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    })
    .then((response) => {
      userFrontHash.value = response.data || null;
    })
    .catch((ex) => {
      Honeybadger.notify(ex, {
        name: "userProfile.js",
        message: "Failed to load user front email hash.",
        params: {
          userId: userId.value,
        },
      });
    })
    .finally(() => {
      fetchUserFrontHashRequest = null;
    });

  return fetchUserFrontHashRequest;
};

const setOrganisationProfile = (userProfile) => {
  const organisation = userProfile?.organisation;

  if (organisation) {
    userOrganisation.value = organisation;
    setOrganisationSettings(organisation?.preferences || {});
    setBranding(organisation?.preferences?.backOfficeBranding || {});
  } else {
    userOrganisation.value = null;
  }
};

const createUserProfile = async ({ email, token, userId }) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const newUserProfileResponse = await axios.post(
      `${MOVEREADY_USER_API_URL}/users`,
      {
        email,
        token,
        userAuthId: userId,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    userProfile.value = newUserProfileResponse.data;
    errorMessage.value = null;
  } catch (ex) {
    errorMessage.value = ex;
    throw ex;
  }
};

const hydrateUserTransactions = (transactions) => {
  if (Array.isArray(transactions)) {
    userTransactions.value = transactions;
    return;
  }

  userTransactions.value = transactions;
};

/**
 * TODO: refactor and remove this.
 */
const toFullName = (user) => {
  const { firstName = "", lastName = "" } = user;

  return `${firstName} ${lastName}`.trim();
};

const fullName = computed(() => toFullName(userProfile.value));

const isExecutive = computed(() => isEstateAgent.value || isAdmin.value);

const isEstateAgent = computed(
  () => userProfile.value?.accountRole === accountRoleName.ESTATE_AGENT
);

const isAdmin = computed(() => userProfile.value?.isAdmin);

const getRoleForTransaction = (transactionId) => {
  const transactions = userProfile.value?.transactions;

  if (!transactions || Object.keys(transactions) === 0) return null;

  //  return transactions[transactionId]?.role || null;

  return (
    transactions.find(
      (transactionRef) => transactionRef.transactionId === transactionId
    )?.role || null
  );
};

const isLandlordForTransaction = (transactionId) => {
  const tx = userProfile.value?.transactions || [];
  const role = tx.find((txRef) => txRef.transactionId === transactionId)?.role;
  //  return tx[transactionId]?.role === participantRoles.LANDLORD;
  return role === participantRoles.LANDLORD;
};

const isSellerForTransaction = (transactionId) => {
  const tx = userProfile.value?.transactions || [];
  const role = tx.find((txRef) => txRef.transactionId === transactionId)?.role;
  //  return tx[transactionId]?.role === participantRoles.SELLER;
  return role === participantRoles.SELLER;
};

const isBuyerForTransaction = (transactionId) => {
  const tx = userProfile.value?.transactions || [];
  const role = tx.find((txRef) => txRef.transactionId === transactionId)?.role;
  //  return [participantRoles.PROSPECTIVE_BUYER, participantRoles.BUYER].includes(
  //    tx[transactionId]?.role
  //  );
  return [participantRoles.PROSPECTIVE_BUYER, participantRoles.BUYER].includes(
    role
  );
};

const isTenantForTransaction = (transactionId) => {
  const tx = userProfile.value?.transactions || [];
  const role = tx.find((txRef) => txRef.transactionId === transactionId)?.role;
  //  return tx[transactionId]?.role === participantRoles.TENANT;
  return role === participantRoles.TENANT;
};

const isExecutiveForTransaction = (transactionId) => {
  const tx = userProfile.value?.transactions || [];
  const role = tx.find((txRef) => txRef.transactionId === transactionId)?.role;
  //  return (
  //    tx[transactionId] &&
  //    tx[transactionId]?.role === participantRoles.ESTATE_AGENT
  //  );
  return role === participantRoles.ESTATE_AGENT;
};

// A client is seller/landlord i.e. a user that has instructed the agent.
// An applicant is a tenant or buyer.
// This is a shorthand instead of both isSeller and isLandlord checks.
const isClientForTransaction = (transactionId) => {
  return (
    isSellerForTransaction(transactionId) ||
    isLandlordForTransaction(transactionId)
  );
};

const isApplicantForTransaction = (transactionId) => {
  return (
    isTenantForTransaction(transactionId) ||
    isBuyerForTransaction(transactionId)
  );
};

/**
 * Deprecated the preferredName and use this to return
 * a preferred name or first name.
 */
const salutation = computed(() => {
  // TODO Use ACTUAL preferred name, when available.
  const { firstName, preferredName } = userProfile.value;

  const salutation = preferredName || firstName || "";

  // Force uppercase first letter
  return salutation.charAt(0).toUpperCase() + salutation.slice(1);
});

/**
 * Should maybe be moved into another composable.
 * Maybe not the best place here anymore, but can be refactored out.
 * This returns a partially hydrated transaction with the address to display a
 * user-friendly list of addresses.
 */
const fetchUserTransactions = async () => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = await getAccessToken();

  try {
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/users/${userId.value}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    hydrateUserTransactions(response.data);
  } catch (ex) {
    if (ex.response?.status === 404) {
      userTransactions.value = [];
    }
    userTransactionsError.value = ex;
  }
};

const defaultUserOfficeId = computed(() =>
  userOffices.value.length === 1 ? userOffices.value[0] : null
);

const updateProfile = async (userId, profileUpdate) => {
  const userAccessToken = await getAccessToken();

  try {
    const appCheckToken = await getAppCheckToken();
    await axios.patch(
      `${MOVEREADY_USER_API_URL}/users/${userId}`,
      profileUpdate,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Unable to update the user profile",
      name: "userProfile.js",
      params: {
        userId,
        profileUpdate,
      },
    });
    throw ex;
  }
};

const firstName = computed(() => userProfile.value?.firstName || "");

export default function useUserProfile() {
  return {
    createUserProfile,
    defaultUserOfficeId,
    fetchUserProfile,
    fetchUserFrontEmailHash,
    fetchUserTransactions,
    fullName,
    getRoleForTransaction,
    isAdmin,
    isApplicantForTransaction,
    isBuyerForTransaction,
    isClientForTransaction,
    isEstateAgent,
    isExecutive,
    isExecutiveForTransaction,
    isLandlordForTransaction,
    isLoadingUserProfile,
    isOrganisationOwner,
    isSellerForTransaction,
    organisationId,
    salutation,
    toFullName,
    userId,
    userOffices,
    userOrganisation,
    userProfile,
    userProfileTransactions,
    userTransactions,
    userFrontHash,
    updateProfile,
    firstName,
  };
}
