export default Object.freeze({
  // An invoice for the estate agent to pay for the MI pack.
  MATERIAL_INFORMATION_PACK: "materialInformationPack",
  // A purchase order for the vendor or landlord to pay for the MI pack.
  MATERIAL_INFORMATION_PACK_CONSUMER_CHARGE:
    "materialInformationPackConsumerCharge",
  CONTRACT_READY_PACK: "contractReadyPack",
  CONTRACT_READY_PACK_SELLER: "contractReadyPackSeller",
  CONNELLS_SALE_READY: "connellsSaleReady",
  IDENTITY_CHECK: "identityCheck",
  SELLER_IDENTITY_CHECK: "identityCheckSeller",
  BUYER_IDENTITY_CHECK: "identityCheckBuyer",
  LANDLORD_IDENTITY_CHECK: "identityCheckLandlord",
  TENANT_IDENTITY_CHECK: "identityCheckTenant",
  CONVEYANCING_QUOTE_SELLER: "conveyancingQuoteSeller",
  CONVEYANCING_QUOTE_BUYER: "conveyancingQuoteBuyer",
  CONNELLS_HOME_CONVEYANCING: "connellsHomeConveyancing",
  CONNELLS_BUYER_HOME_CONVEYANCING: "connellsBuyerHomeConveyancing",
  IDENTITY_CHECK_VALIDATE: "identityCheckValidate",
  CONTRACT_SIGNING: "contractSigning",
  // A product for testing AI insights
  INSIGHTS: "insights",
});
