import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import participantRoles from "@/enums/participant-roles";
import useAuth from "@/composables/auth";
import useIdentification from "@/composables/identification/identification";
import useOffices from "@/composables/organisation/offices";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";

const { identityProvider: identityProviderName } = useIdentification();
const { getAccessToken } = useAuth();
const { hasOffices } = useOffices();

const configuredUpgradeProducts = ref({});
const consumerCharges = ref({});
const emails = ref({});
const identityProvider = ref(identityProviderName.ONFIDO);
const isBuyerIdentityChecksEnabled = ref(null);
const isClientExternalLinkDisabled = ref(false);
const isClientThirdPartyConsentRequired = ref(false);
const isConnellsSaleReadyEnabled = ref(null);
const isIdentityProviderSettingEnabled = ref(false);
const isLandlordIdentityCheckRequiredByDefault = ref(null);
const isLegalPartnershipEnabled = ref(null);
const isLmsWorkspaceCreationRequired = ref(false);
const isMatchmakerIdRequired = ref(false);
const isOfficeRestrictionEnabled = ref(false);
const isSaleReadyEnabled = ref(false);
const isSavingSettings = ref(false);
const isSellerContractReadyPaymentEnabled = ref(null);
const isSellerIdentityCheckRequiredByDefault = ref(null);
const isSellerIdentityChecksEnabled = ref(null);
const isUserOfficeManagementEnabled = ref(false);
const offices = ref([]);
const organisationSettings = ref({});
const isConsumerChargeOptionalityEnabled = ref(false);
const contracts = ref([]);
const nudges = ref({});
const profile = ref({});

const isClientIdentityChecksEnabled = computed(
  () => isSellerIdentityChecksEnabled.value
);

const isApplicantIdentityChecksEnabled = computed(
  () => isBuyerIdentityChecksEnabled.value
);

const emailRecipient = Object.freeze({
  AGENTS_ASSIGNED_TO_TRANSACTION: "agents",
  ORGANISATION_RECIPIENTS: "thirdParty",
  DEFAULT_TO_ORGANISATION: null,
});

const getSortedOffices = (officesPreference) => {
  const offices = Array.isArray(officesPreference) ? officesPreference : [];

  return offices.sort((office, officeToCompate) => {
    if (office < officeToCompate) {
      return -1;
    }
    if (office > officeToCompate) {
      return 1;
    }
    return 0;
  });
};

const setOrganisationSettings = (preferences = {}) => {
  organisationSettings.value = preferences;

  if (preferences?.identityProvider) {
    identityProvider.value = preferences.identityProvider;
  }

  emails.value = preferences.emails || {};
  offices.value = getSortedOffices(preferences?.offices);
  isMatchmakerIdRequired.value = preferences?.isMatchmakerIdRequired === true;

  isLmsWorkspaceCreationRequired.value =
    preferences?.isLmsWorkspaceCreationRequired === true;

  configuredUpgradeProducts.value =
    preferences?.configuredUpgradeProducts || {};

  consumerCharges.value = preferences?.consumerCharges || {};
  nudges.value = preferences?.nudges || {};
  profile.value = preferences?.profile || {};
  contracts.value = preferences?.contracts || [];

  isConsumerChargeOptionalityEnabled.value =
    typeof preferences?.consumerCharges?.isConsumerChargeOptionalityEnabled ===
    "boolean"
      ? preferences.consumerCharges.isConsumerChargeOptionalityEnabled
      : false;

  isOfficeRestrictionEnabled.value =
    typeof preferences?.isOfficeRestrictionEnabled === "boolean"
      ? preferences?.isOfficeRestrictionEnabled
      : false;

  isUserOfficeManagementEnabled.value =
    typeof preferences?.isUserOfficeManagementEnabled === "boolean"
      ? preferences?.isUserOfficeManagementEnabled
      : false;

  isSaleReadyEnabled.value =
    typeof preferences?.isSaleReadyEnabled === "boolean"
      ? preferences?.isSaleReadyEnabled
      : false;

  isIdentityProviderSettingEnabled.value =
    typeof preferences?.isIdentityProviderSettingEnabled === "boolean"
      ? preferences?.isIdentityProviderSettingEnabled
      : false;

  isClientThirdPartyConsentRequired.value =
    typeof preferences?.isClientThirdPartyConsentRequired === "boolean"
      ? preferences?.isClientThirdPartyConsentRequired
      : false;

  isSellerIdentityCheckRequiredByDefault.value =
    typeof preferences?.isSellerIdentityCheckRequiredByDefault === "boolean"
      ? preferences?.isSellerIdentityCheckRequiredByDefault
      : null;

  isLandlordIdentityCheckRequiredByDefault.value =
    typeof preferences?.isLandlordIdentityCheckRequiredByDefault === "boolean"
      ? preferences?.isLandlordIdentityCheckRequiredByDefault
      : null;

  isConnellsSaleReadyEnabled.value =
    typeof preferences?.isConnellsSaleReadyEnabled === "boolean"
      ? preferences?.isConnellsSaleReadyEnabled
      : null;

  isSellerIdentityChecksEnabled.value =
    typeof preferences?.isSellerIdentityChecksEnabled === "boolean"
      ? preferences?.isSellerIdentityChecksEnabled
      : null;

  isBuyerIdentityChecksEnabled.value =
    typeof preferences?.isBuyerIdentityChecksEnabled === "boolean"
      ? preferences?.isBuyerIdentityChecksEnabled
      : null;

  isLegalPartnershipEnabled.value =
    typeof preferences?.isLegalPartnershipEnabled === "boolean"
      ? preferences?.isLegalPartnershipEnabled
      : null;

  isSellerContractReadyPaymentEnabled.value =
    typeof preferences?.isSellerContractReadyPaymentEnabled === "boolean"
      ? preferences?.isSellerContractReadyPaymentEnabled
      : null;

  isClientExternalLinkDisabled.value =
    typeof preferences?.isClientExternalLinkDisabled === "boolean"
      ? preferences?.isClientExternalLinkDisabled
      : false;
};

const updateOrganisationSettings = async (organisationId, options = {}) => {
  isSavingSettings.value = true;

  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = await getAccessToken();
    await axios.patch(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/preferences`,
      options,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisationSettings.js",
      message: `Failed to update organisation settings for organisationId: ${organisationId}`,
      params: {
        organisationId,
        options,
      },
    });
    throw ex;
  } finally {
    isSavingSettings.value = false;
  }
};

/**
 * Quick re-factor win to show offices - maybe should use the offices.js composable.
 */
const isOfficeManagementEnabled = computed(() => {
  return hasOffices.value;
});

const isIdentityCheckRequiredByRole = (participantRole) => {
  const isClientInvite = [
    participantRoles.SELLER,
    participantRoles.LANDLORD,
  ].includes(participantRole);
  const isApplicantInvite = [
    participantRoles.BUYER,
    participantRoles.TENANT,
  ].includes(participantRole);

  // Applicant (buyer/tenant) identity checks are enabled by default as that's the only step they have currently.
  if (
    (isClientIdentityChecksEnabled.value && isClientInvite) ||
    isApplicantInvite
  )
    return true;

  return false;
};

/**
 * This may become organisaiton specific, but it's also
 * used in several places so the copy is set here to be reused.
 */
const thirdPartyConsentLabel =
  "I confirm compliance with applicable terms, conditions, and GDPR requirements, including obtaining necessary consents";

const hasConfiguredUpgradeProducts = computed(() => {
  const { purchasedProducts = [], requiresPurchase = [] } =
    configuredUpgradeProducts.value || {};

  return [...purchasedProducts, ...requiresPurchase].length > 0;
});

const isContractsEnabled = computed(() => {
  return contracts.value.length > 0;
});

export default function useOrganisationSettings() {
  return {
    configuredUpgradeProducts,
    consumerCharges,
    contracts,
    emailRecipient,
    emails,
    hasConfiguredUpgradeProducts,
    profile,
    identityProvider,
    isApplicantIdentityChecksEnabled,
    isBuyerIdentityChecksEnabled,
    isClientExternalLinkDisabled,
    isClientIdentityChecksEnabled,
    isClientThirdPartyConsentRequired,
    isConnellsSaleReadyEnabled,
    isConsumerChargeOptionalityEnabled,
    isContractsEnabled,
    isIdentityCheckRequiredByRole,
    isIdentityProviderSettingEnabled,
    isLandlordIdentityCheckRequiredByDefault,
    isLegalPartnershipEnabled,
    isLmsWorkspaceCreationRequired,
    isMatchmakerIdRequired,
    isOfficeManagementEnabled,
    isOfficeRestrictionEnabled,
    isSaleReadyEnabled,
    isSellerContractReadyPaymentEnabled,
    isSellerIdentityCheckRequiredByDefault,
    isSellerIdentityChecksEnabled,
    isUserOfficeManagementEnabled,
    nudges,
    offices,
    organisationSettings,
    setOrganisationSettings,
    thirdPartyConsentLabel,
    updateOrganisationSettings,
  };
}
