export default [
  {
    path: "/terms",
    name: "legal.terms",
    component: () =>
      import(
        /* webpackChunkName: "legal" */ "../../views/legal/TermsAndConditions.vue"
      ),
    meta: {
      title: "Terms and conditions",
    },
  },
  {
    path: "/privacy",
    name: "legal.privacy",
    component: () =>
      import(
        /* webpackChunkName: "legal" */ "../../views/legal/PrivacyPolicy.vue"
      ),
    meta: {
      title: "Privacy policy",
    },
  },
  {
    path: "/cookies",
    name: "legal.cookies",
    component: () =>
      import(/* webpackChunkName: "legal" */ "../../views/legal/CookieUse.vue"),
    meta: {
      title: "Cookie use",
    },
  },
  {
    path: "/services",
    name: "legal.services",
    component: () =>
      import(
        /* webpackChunkName: "legal" */ "../../views/legal/ServicesAndFees.vue"
      ),
    meta: {
      title: "Services and fees",
    },
  },
  {
    path: "/consumer-charges",
    name: "legal.consumer-charges",
    component: () =>
      import(
        /* webpackChunkName: "legal" */ "../../views/legal/ConsumerChargesPolicy.vue"
      ),
    meta: {
      title: "Consumer Charges policy",
    },
  },
  {
    path: "/promotions",
    name: "legal.promotions",
    component: () =>
      import(
        /* webpackChunkName: "legal" */ "../../views/legal/Promotions.vue"
      ),
    meta: {
      title: "Promotions",
    },
  },
];
