import categoryToOverlayMapping from "@/enums/category-overlay-mapping";
import propertyPackTasks from "@/schemas/propertyPackTasks";
import useAuth from "@/composables/auth";
import useChecks from "@/composables/flags/checks";
import useDocumentSigning from "@/composables/documentSigning";
import useSaleConveyancing from "@/composables/conveyancing/saleConveyancing";
import useTransactionProducts from "@/composables/transaction/transactionProducts";
import useUserProfile from "@/composables/user/userProfile";
import useContracts from "@/composables/contracts/contracts";
import { checkSeverity } from "@/enums/checks";
import { computed } from "vue";

const { checks, filterFlagsByName } = useChecks();
const { isReadyToSignOrHasSigned, sellerSignatureFlags } = useDocumentSigning();
const {
  hasPurchasedMaterialInformationPack,
  isConnellsSaleReadyRequired,
  hasPurchasedConnellsSaleReady,
  hasPurchasedConnellsHomeConveyancing,
  isSellerSaleReadyRequired,
  isUserIdentityCheckRequired,
} = useTransactionProducts();
const { userId } = useAuth();
const { isExecutive } = useUserProfile();
const { isSellerConveyancerConfirmed } = useSaleConveyancing();
const { userContractFlags } = useContracts();

const CONTRACT_READY_UPGRADE_USER_STEP = "upgrade";
const IDENTITY_USER_STEP = "identity";
const LISTING_QUESTIONS_USER_STEP = "listing";
const LETTINGS_LISTING_QUESTIONS_USER_STEP = "lettingsListing";
const MATERIAL_INFO_QUESTIONS_USER_STEP = "materialInfo";
const LEGAL_INFO_QUESTIONS_USER_STEP = "legalInfo";
const PROPERTY_QUESTIONS_USER_STEP = "propertyQuestions";
const LEASEHOLD_QUESTIONS_USER_STEP = "leaseholdQuestions";
const FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP = "fittingsAndContents";
const PROPERTY_LAWYER_USER_STEP = "addLawyer";
const CONNELLS_CONFIRMATION_USER_STEP = "connellsConfirmation";
const SALE_READY_CONFIRMATION_STEP = "saleReadyConfirmation";
const SELLER_DECLARATION = "sellerDeclaration";
const CONTRACT_SIGNING_USER_STEP = "contractSigning";

const ALL_USER_STEPS = {
  [CONTRACT_SIGNING_USER_STEP]: {
    name: "Sign your agreement",
    duration: "(1 minute)",
    pageLead: "Sign your agency agreement online.",
    description: "Easily and quickly sign your agency agreement online.",
    category: "contract-signatures",
    order: 1,
    initialRoute: {
      name: "property-pack.contract-signatures",
    },
    showProgressMenu: true,
  },
  [SALE_READY_CONFIRMATION_STEP]: {
    name: "Let&rsquo;s get Sale Ready",
    pageLead:
      "Stand out in the market and attract more buyers with Moverly's Sale Ready process.",
    description:
      "Increase your property&rsquo;s appeal, preparing it for purchase, and allowing it to be marketed as Sale Ready.",
    category: "sale-ready-confirmation",
    order: 25,
    component: "UserStepsSaleReadyItem",
  },
  [CONTRACT_READY_UPGRADE_USER_STEP]: {
    name: "Upgrade to Sale Ready",
    description: "",
    category: "seller-payment",
    initialRoute: {
      name: "setup.payment",
    },
    // This should be true, but the flag contractReadySellerPurchaseOrder is missing the category, so this allow a user to pay.
    allowViewIfComplete: true,
    order: 25,
  },
  [IDENTITY_USER_STEP]: {
    name: "Verify your identity", // "Confirm your personal details" if no ID checks
    description: "A legal requirement for all sellers.", // modified to 'all landlords' for lettings
    duration: "(5 minutes)",
    category: "seller-info", // modified to 'landlord-info' for lettings
    initialRoute: {
      name: "participants.identity.introduction",
    },
    allowViewIfComplete: true,
    showProgressMenu: true,
    // Needed at the moment as you can't really skip the personal details and go straight to IDV.
    disableProgressMenuLinks: true,
    order: 10,
  },
  [LISTING_QUESTIONS_USER_STEP]: {
    name: "Confirm upfront property information",
    description:
      "Your property can't legally be listed without it - to be completed as soon as possible.",
    pageLead:
      "Information required to be compliant with Trading Standards regulations, and get your property listed.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription: "",
    duration: "(5&#8211;10 minutes)",
    category: "listing",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.listing.start",
    },
    showProgressMenu: true,
    order: 20,
  },
  [LETTINGS_LISTING_QUESTIONS_USER_STEP]: {
    name: "Confirm upfront property information",
    description:
      "Your property can't legally be listed without it - to be completed as soon as possible.",
    pageLead:
      "Information required to be compliant with Trading Standards regulations, and get your property listed.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription: "",
    duration: "(5&#8211;10 minutes)",
    category: "lettings-listing",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.lettings-listing.start",
    },
    showProgressMenu: true,
    order: 20,
  },
  [MATERIAL_INFO_QUESTIONS_USER_STEP]: {
    name: "Property information",
    description: "Includes disputes, notices and environmental issues.",
    pageLead:
      "Vital information about the property to progress your sale quickly.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription:
      "Whether you are the legal owner of the property or representing them you should complete this form as though the questions are being answered by the legal owner and based, as far as possible, on their personal knowledge of the property. The information you provide must be accurate. You must complete this form to comply with the Consumer Protection from Unfair Trading Regulations 2008 during the marketing of the property. Under these regulations it is a legal requirement to disclose anything that would materially affect the average person&rsquo;s decision to buy the property.",
    duration: "(15&#8211;20 minutes)",
    category: "material-info",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.material-info.start",
    },
    showProgressMenu: true,
    order: 20,
  },
  [LEGAL_INFO_QUESTIONS_USER_STEP]: {
    name: "Legal information",
    description: "Information required for the legal part of the process.",
    pageLead:
      "This information is required for the legal process for a speedy completion. It&rsquo;ll help your property lawyer get your sale moving.",
    feedbackMessage:
      "Moverly has automatically completed some of the questions. You just need answer the remaining questions based on your personal knowledge of the property.",
    pageDescription:
      "Your property lawyer and your buyer's property lawyer and valuer will use this information as part of the legal process for selling your home and it will form part of the contract for sale. You must answer all questions or it will delay the legal process. You must not leave out information which could affect a buyer's decision to proceed. If any of the information changes before you complete the sale then you should let your estate agent and your property lawyer know about the changes, in writing, as soon as possible.",
    duration: "(10&#8211;15 minutes)",
    category: "legal-info",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.legal-info.start",
    },
    showProgressMenu: true,
    order: 50,
  },
  [PROPERTY_QUESTIONS_USER_STEP]: {
    name: "Provide detailed property information",
    description: "Includes boundaries, alterations and rights",
    pageDescription: "",
    pageLead:
      "This section completed by the seller to supply the detailed information and documents which may be relied upon for the conveyancing process.",
    feedbackMessage:
      "The answers should be prepared by the person or persons who are named as owner on the deeds or Land Registry title or by the owner's legal representative(s) if selling under a power of attorney or grant of probate or representation. If there is more than one seller, you should prepare the answers together.",
    duration: "(20&#8211;30 minutes)",
    category: "property-questions",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.property-questions.start",
    },
    showProgressMenu: true,
    order: 30,
  },
  [LEASEHOLD_QUESTIONS_USER_STEP]: {
    name: "Provide leasehold information",
    description:
      "Includes documents, lease contact details, and service charges.",
    pageLead: "You need to provide information about your lease.",
    pageDescription: "",
    feedbackMessage:
      "The seller should provide all relevant documentation relating to the lease. This may include documents given to the seller when they purchased the property, or documents subsequently given to the seller by those managing the property.",
    duration: "(20&#8211;30 minutes)",
    category: "leasehold-questions",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.leasehold-questions.start",
    },
    showProgressMenu: true,
    order: 40,
  },
  [FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP]: {
    name: "Describe fittings, fixtures and contents",
    description: "What's included and not included in the sale.",
    pageLead:
      "The aim of this section is to make clear to the buyer which items are included in the sale. It must be completed accurately by the seller as the answers may become part of the contract between the buyer and seller",
    pageDescription: "",
    feedbackMessage:
      "Where an item is excluded from the sale the seller may offer it for sale by specifying a price. The buyer can then decide whether to accept the seller's offer. A seller who specifies a price is responsible for negotiating the sale of that item directly with the buyer or through their estate agent.",
    duration: "(10&#8211;20 minutes)",
    category: "fittings-and-contents",
    allowViewIfComplete: true,
    initialRoute: {
      name: "property-pack.fittings-and-contents.start",
    },
    showProgressMenu: true,
    order: 60,
  },
  [CONNELLS_CONFIRMATION_USER_STEP]: {
    name: "Your property lawyer",
    description:
      "Confirm your answers and send your information to your property lawyer.",
    duration: "(1 minute)",
    category: "saleReady",
    initialRoute: {
      name: "sale-ready.review.sale",
    },
    showProgressMenu: false,
    order: 70,
  },
  [PROPERTY_LAWYER_USER_STEP]: {
    name: "Share with your property lawyer",
    description:
      "Provide contact details so we can share your Sale Ready pack with them.",
    duration: "(2 minutes)",
    category: "sellers-conveyancer-info",
    initialRoute: {
      name: "participant.conveyancer.add",
    },
    showProgressMenu: false,
    order: 70,
  },
  [SELLER_DECLARATION]: {
    name: "Confirm and sign",
    description:
      "Confirm all your information and digitally sign your documents.",
    duration: "(2 minutes)",
    category: "seller-signatures",
    initialRoute: {
      name: "property-pack.seller-signatures",
    },
    showProgressMenu: true,
    order: 90,
  },
};

const contractReadySellerPurchaseOrderFlag = computed(() => {
  const crFlag = filterFlagsByName(
    "contractReadySellerPurchaseOrder",
    checks.value,
    true
  );
  return crFlag;
});

const hasPurchasedContractReadyPack = computed(() => {
  // NB this won't work for EA purchase of CR pack
  // We do this as we can't rely on the transaction state which is where
  // the purchasedProducts are updated
  return (
    contractReadySellerPurchaseOrderFlag?.value?.severity ===
    checkSeverity.SUCCESS
  );
});

const hasLeasehold = computed(() => {
  // leasehold-questions checks are only present if there is at least
  // one leasehold in the ownership state
  const leaseholdChecks = stepChecks(LEASEHOLD_QUESTIONS_USER_STEP);
  return leaseholdChecks?.length > 0;
});

const isLettings = computed(() => {
  // lettings-listing flags are only present if the transaction is a lettings one
  const lettingsChecks = stepChecks(LETTINGS_LISTING_QUESTIONS_USER_STEP);
  return lettingsChecks?.length > 0;
});

const updateIdentityStep = () => {
  const isIdentityCheckRequired = isUserIdentityCheckRequired(userId.value);

  if (!isIdentityCheckRequired) {
    ALL_USER_STEPS[IDENTITY_USER_STEP].name = "Confirm your details";
    ALL_USER_STEPS[IDENTITY_USER_STEP].description =
      "Including your full name residential address.";
  }

  if (isLettings.value) {
    ALL_USER_STEPS[IDENTITY_USER_STEP].category = "landlord-info";
    ALL_USER_STEPS[IDENTITY_USER_STEP].initialRoute = {
      name: "participants.identity.introduction",
    };

    if (isIdentityCheckRequired) {
      ALL_USER_STEPS[IDENTITY_USER_STEP].description =
        "A legal requirement for all landlords.";
    }
  }
};

const updatePropertyLawyerStep = () => {
  const step = ALL_USER_STEPS[CONNELLS_CONFIRMATION_USER_STEP];

  if (hasPurchasedConnellsHomeConveyancing.value) {
    step.name = "Your Home Conveyancing property lawyer";
    step.description = "Your details have been sent to get your sale underway.";
  } else {
    step.name = "Your property lawyer";
    step.description =
      "Provide contact details so we can share your Sale Ready pack with them.";
  }
};

const getContractSigningSteps = () => {
  if (userContractFlags.value.length > 0) {
    return [CONTRACT_SIGNING_USER_STEP];
  }

  return [];
};

const activeUserSteps = computed(() => {
  // this allows us to dynamically configure the steps displayed
  // depending on the options chose by the agent or changes in the
  // state of the property pack itself
  updateIdentityStep();
  updatePropertyLawyerStep();
  let activeStepNames = [
    ...getContractSigningSteps(),
    IDENTITY_USER_STEP,
    ...getMaterialInformationSteps(),
    ...getSaleReadySteps(),
  ];

  const sortedSteps = activeStepNames.sort((stepName, stepNameToCompare) => {
    const stepNameOrder = ALL_USER_STEPS[stepName]?.order || 9999;
    const stepNameToCompareOrder =
      ALL_USER_STEPS[stepNameToCompare]?.order || 9999;

    return stepNameOrder > stepNameToCompareOrder ? 1 : -1;
  });

  const hydratedSteps = sortedSteps.map((stepName) => {
    return { stepName, ...ALL_USER_STEPS[stepName] };
  });

  return hydratedSteps;
});

const getMaterialInformationSteps = () => {
  const materialInformationSteps = [];

  if (hasPurchasedMaterialInformationPack.value) {
    if (isLettings.value) {
      materialInformationSteps.push(LETTINGS_LISTING_QUESTIONS_USER_STEP);
    } else {
      materialInformationSteps.push(LISTING_QUESTIONS_USER_STEP);
    }
  }

  return materialInformationSteps;
};

const getSaleReadySteps = () => {
  const saleReadySteps = [];

  if (isSellerSaleReadyRequired.value) {
    saleReadySteps.push(CONTRACT_READY_UPGRADE_USER_STEP);

    if (hasPurchasedContractReadyPack.value) {
      saleReadySteps.push(PROPERTY_QUESTIONS_USER_STEP);

      if (hasLeasehold.value) {
        saleReadySteps.push(LEASEHOLD_QUESTIONS_USER_STEP);
      }

      saleReadySteps.push(
        FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
        PROPERTY_LAWYER_USER_STEP
      );
    }
  }

  if (isConnellsSaleReadyRequired.value) {
    saleReadySteps.push(SALE_READY_CONFIRMATION_STEP);
  }

  if (hasPurchasedConnellsSaleReady.value) {
    saleReadySteps.push(PROPERTY_QUESTIONS_USER_STEP);

    if (hasLeasehold.value) {
      saleReadySteps.push(LEASEHOLD_QUESTIONS_USER_STEP);
    }

    saleReadySteps.push(
      FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
      CONNELLS_CONFIRMATION_USER_STEP
    );

    if (sellerSignatureFlags.value.length > 0) {
      saleReadySteps.push(SELLER_DECLARATION);
    }
  }

  return saleReadySteps;
};

const orderFlagsByUiSchema = (unorderedFlags) => {
  const orderedFlags = [...unorderedFlags].sort((flag, comparisonFlag) => {
    const flagIndex = propertyPackTasks.findIndex(
      ({ path, category }) => path === flag.path && category === flag.category
    );
    const comparisonFlagIndex = propertyPackTasks.findIndex(
      ({ path, category }) =>
        path === comparisonFlag.path && category === comparisonFlag.category
    );
    return flagIndex - comparisonFlagIndex;
  });
  return orderedFlags;
};

const stepChecks = (stepName) => {
  const filteredChecks = (checks.value || []).filter(
    (check) =>
      check.category === ALL_USER_STEPS[stepName].category &&
      // temp fudge as we've deprecated this step
      check.checkName !== "Asking price"
  );

  return orderFlagsByUiSchema(filteredChecks);
};

const getFirstCheck = (stepName) => {
  const checks = stepChecks(stepName);
  return checks[0];
};

const isFirstCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const firstCheck = checks[0];
  return firstCheck?.path === path;
};

const isLastCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const lastCheck = checks[checks.length - 1];
  return lastCheck?.path === path;
};

const getNextCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const currentCheckIndex = checks.findIndex((check) => check.path === path);
  if (currentCheckIndex === checks.length - 1) return undefined;
  if (currentCheckIndex === -1) return undefined;
  return checks[currentCheckIndex + 1];
};

const getPreviousCheck = (path, stepName) => {
  const checks = stepChecks(stepName);
  const currentCheckIndex = checks.findIndex((check) => check.path === path);
  if (currentCheckIndex < 0) return undefined;
  return checks[currentCheckIndex - 1];
};

const incompleteChecks = (checks) => {
  return (checks || []).filter(
    (check) => check.severity !== checkSeverity.SUCCESS
  );
};

const getNextIncompleteCheck = (currentPath, stepName) => {
  const checks = stepChecks(stepName);
  const currentCheckIndex = checks.findIndex(
    (check) => check.path === currentPath
  );
  return checks
    .slice(currentCheckIndex + 1)
    .find(({ severity }) => severity !== checkSeverity.SUCCESS);
};

const isStepComplete = (stepName) => {
  return incompleteChecks(stepChecks(stepName)).length === 0;
};

const percentStepComplete = (stepName) => {
  const allChecks = stepChecks(stepName);
  const total = allChecks.length;
  const complete = total - incompleteChecks(allChecks).length;
  const fraction = complete / total;

  if (isNaN(fraction)) return 0;
  if (fraction > 1) return 100;
  if (fraction < 0) return 0;
  return fraction * 100;
};

const hasStartedStep = (stepName) => percentStepComplete(stepName) > 0;

const activeStep = () => {
  // return the first step which has incomplete items
  return activeUserSteps.value.findIndex(
    (step) => !isStepComplete(step.stepName)
  );
};

const showProgressMenu = (stepName) =>
  !!ALL_USER_STEPS[stepName]?.showProgressMenu;

const hasStepTag = (stepName) => !!ALL_USER_STEPS[stepName]?.tag;

const isAllStepsCompleted = computed(() =>
  activeUserSteps.value.every((step) => isStepComplete(step.stepName))
);

const isStepDisabledByUserRole = (stepName, isExecutive) => {
  return (
    [IDENTITY_USER_STEP, SELLER_DECLARATION].includes(stepName) && isExecutive
  );
};

const isStepGated = (stepName) => {
  if (stepName === SELLER_DECLARATION) {
    return (
      !isReadyToSignOrHasSigned.value || !isSellerConveyancerConfirmed.value
    );
  }

  // Force the step to not be disabled is CHC has ben purchased but for whatever reason, the MI data becomes invalid.
  if (
    stepName === SALE_READY_CONFIRMATION_STEP &&
    !hasPurchasedConnellsSaleReady.value
  ) {
    const ntsCompletionFlag = filterFlagsByName(
      "ntsCompletion",
      checks.value,
      true
    );
    const isNtsIncomplete =
      ntsCompletionFlag?.severity !== checkSeverity.SUCCESS;

    return isNtsIncomplete;
  }

  if (SELLER_DECLARATION === stepName) {
    return !isSellerConveyancerConfirmed.value;
  }

  if (CONNELLS_CONFIRMATION_USER_STEP === stepName) {
    if (hasPurchasedConnellsHomeConveyancing.value) {
      return false;
    }

    const taCompletionFlags = filterFlagsByName(
      ["ta6Completion", "ta10Completion", "ta7Completion"],
      checks.value
    );

    return !taCompletionFlags.every(
      (flag) => flag.severity === checkSeverity.SUCCESS
    );
  }

  return false;
};

/**
 * Customise this for now as some steps have specific criteria.
 */
const isStepDisabled = (stepName) => {
  ///   (isSaleReady.value && stepName !== IDENTITY_USER_STEP) ||

  if (
    isStepDisabledByUserRole(stepName, isExecutive.value) ||
    isStepGated(stepName)
  ) {
    return true;
  }

  return false;
};

export default function useUserTasks() {
  return {
    ALL_USER_STEPS,
    CONNELLS_CONFIRMATION_USER_STEP,
    CONTRACT_READY_UPGRADE_USER_STEP,
    FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
    IDENTITY_USER_STEP,
    LEASEHOLD_QUESTIONS_USER_STEP,
    LEGAL_INFO_QUESTIONS_USER_STEP,
    LETTINGS_LISTING_QUESTIONS_USER_STEP,
    LISTING_QUESTIONS_USER_STEP,
    MATERIAL_INFO_QUESTIONS_USER_STEP,
    PROPERTY_LAWYER_USER_STEP,
    PROPERTY_QUESTIONS_USER_STEP,
    SALE_READY_CONFIRMATION_STEP,
    SELLER_DECLARATION,
    CONTRACT_SIGNING_USER_STEP,
    activeStep,
    activeUserSteps,
    categoryToOverlayMapping,
    getFirstCheck,
    getNextCheck,
    getNextIncompleteCheck,
    getPreviousCheck,
    hasLeasehold,
    hasPurchasedContractReadyPack,
    hasStartedStep,
    hasStepTag,
    incompleteChecks,
    isAllStepsCompleted,
    isFirstCheck,
    isLastCheck,
    isLettings,
    isStepComplete,
    isStepDisabled,
    orderFlagsByUiSchema,
    percentStepComplete,
    showProgressMenu,
    stepChecks,
  };
}
