<script setup>
import { computed } from "vue";
import IconDanger from "@/components/library/icons/DangerIcon";
import IconSuccess from "@/components/library/icons/SuccessIcon";
import IconWarning from "@/components/library/icons/WarningIcon";
import RunningIcon from "@/components/library/icons/Running";

const props = defineProps({
  content: {
    type: String,
    default: "",
  },
  isPrimary: {
    type: Boolean,
    default: false,
  },
  isSuccess: {
    type: Boolean,
    default: false,
  },
  isWarning: {
    type: Boolean,
    default: false,
  },
  isDanger: {
    type: Boolean,
    default: false,
  },
  isPending: {
    type: Boolean,
    default: false,
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  noWrap: {
    type: Boolean,
    default: false,
  },
  // Not the best - but a modifier for the main severity.
  isBackground: {
    type: Boolean,
    default: false,
  },
});

const hasIcon = computed(() => !!iconComponent.value);
const iconComponent = computed(() => {
  if (!props.showIcon) return null;
  if (props.isSuccess) return IconSuccess;
  if (props.isWarning) return IconWarning;
  if (props.isDanger) return IconDanger;
  if (props.isPending) return RunningIcon;

  return null;
});
</script>

<script>
export default {
  name: "TagComponent",
};
</script>

<template>
  <span
    class="tag"
    :class="{
      'tag--nowrap': noWrap,
      'tag--primary': isPrimary,
      'tag--success': isSuccess,
      'tag--warning': isWarning,
      'tag--pending': isPending,
      'tag--danger': isDanger,
      'tag--background': isBackground,
    }"
  >
    <span class="tag__icon" v-if="hasIcon"
      ><slot name="icon"
        ><component :is="iconComponent" v-if="iconComponent" /></slot
    ></span>
    <span class="tag__label"
      ><slot>{{ content }}</slot></span
    >
  </span>
</template>

<style lang="scss">
.tag {
  align-self: center;
  background: rgba($color-typography, 0.08);
  color: $color-typography;
  align-items: center;
  border-radius: radius(weak);
  display: inline-flex;
  font-size: typography-size("meta");
  font-weight: typography-weight("meta");
  line-height: typography-line-height("meta");
  padding: 1px gutter();

  .icon__canvas {
    height: auto;
    width: 16px;
  }
}

.tag__icon {
  display: inline-flex;
  margin-right: gutter(weak);
}

.tag__label {
  display: inline-flex;
}

.tag--primary {
  background: $color-primary;
  color: $color-on-primary;
}

.tag--success {
  background: $color-success;
  color: $color-on-success;

  .icon__glyph {
    fill: $color-on-success;
  }
}

.tag--success.tag--background {
  background: #c4ebdf;
  color: inherit;

  .icon__glyph {
    fill: inherit;
  }
}

.tag--warning {
  background: $color-warning;
  color: $color-on-warning;

  .icon__glyph {
    fill: $color-on-warning;
  }
}

.tag--danger {
  background: $color-danger;
  color: $color-on-danger;

  .icon__glyph {
    fill: $color-on-danger;
  }
}

.tag--danger.tag--background {
  background: #f8eff0;
  color: inherit;

  .icon__glyph {
    fill: inherit;
  }
}

.tag--suggestion {
  background: $color-info-background;
  color: $color-info;

  .icon__glyph {
    fill: $color-info;
  }
}

.tag--pending {
  background: $color-help;
  color: $color-on-help;

  .icon__glyph {
    fill: $color-on-help;
  }
}

.tag--nowrap {
  white-space: nowrap;
}

.tag--gutter {
  margin-left: gutter();
  margin-right: gutter();
}
</style>
